@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  @apply text-mainTextColor;
 /*@apply bg-gradient-to-b  bg-bgColor  to-black;*/
 @apply bg-bgColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  box-sizing: border-box;
}
* {
  box-sizing: border-box;
}

#hamburger-menu{
  height: calc(100vh - 51px);
}

input{
  height:20px;
  padding:20px;
  @apply bg-white/10;
  @apply text-secTextColor;
  @apply border-[1px] border-customBorderColor;
  @apply my-2;
}

textarea{
  @apply bg-white/10;
  @apply border-[1px] border-customBorderColor;
  @apply text-secTextColor;
  
}

.break-words {
  word-break: break-word;
  overflow-wrap: break-word;
}

.hashtag  a {
  
  @apply text-linkText
}
.hashtag  a:hover {
  @apply text-linkTextHover
}

.modal-background{
  @apply bg-white/20;
}

/* Add this to your CSS file */
.ReactModal__Overlay {
  z-index: 50 !important;
}